import { Container } from "@mui/material";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import React from "react";
import InfoWithVideo from "../components/public/InfoWithVideo";
import IntroSection from "../components/public/IntroSection";
import SignUpFormLink from "../components/public/SignUpFormLink";
import PublicLayout from "../layout/PublicLayout";

const ForApplicants: React.FC<any> = ({ data }) => {
  const {
    mdx: {
      fields: {
        frontmatter: {
          seoTitle,
          header,
          contentText,
          videoTitle,
          video,
          videoThumbnail,
          actionSubtitle,
          actionTitle
        }
      }
    },
    headerImage: headerImageRes
  } = data;
  const headerImage = getImage(headerImageRes);

  return (
    <PublicLayout seoTitle={seoTitle}>
      <IntroSection image={headerImage} imageOverlay title="Applicants" />
      <Container>
        <InfoWithVideo
          header={header}
          content={contentText}
          video={{ src: video, title: videoTitle, thumbnail: videoThumbnail }}
        />
        <SignUpFormLink subtitle={actionSubtitle} title={actionTitle} type="applicant" />
      </Container>
    </PublicLayout>
  );
};

export const applicantsPageQuery = graphql`
  query ApplicantsPage($id: String!) {
    mdx(id: { eq: $id }) {
      fields {
        frontmatter {
          seoTitle
          header
          contentText
          videoTitle
          videoThumbnail
          video
          actionSubtitle
          actionTitle
        }
      }
    }

    headerImage: file(relativePath: { eq: "forApplicants.png" }) {
      ...fluidImage
    }
  }
`;

export default ForApplicants;
